import { render, staticRenderFns } from "./gjcRegister.vue?vue&type=template&id=5f439f67&scoped=true"
import script from "./gjcRegister.vue?vue&type=script&lang=js"
export * from "./gjcRegister.vue?vue&type=script&lang=js"
import style0 from "./gjcRegister.vue?vue&type=style&index=0&id=5f439f67&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f439f67",
  null
  
)

export default component.exports