<template>
  <div v-if="argumentData.retCode !== '0'" class="orderpay_page">
    <div class="red-box">
      <p>请在就诊时段前完成支付，超时订单将取消</p>
    </div>
    <div class="page-box">
      <div class="card-box">
        <div class="cell">
          <div class="cell_title">
            订单号
          </div>
          <div class="cell_value middle-color">
            {{ details.ddh }}
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            订单名称
          </div>
          <div class="cell_value middle-color">
            {{ details.ddlxmc }}
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            应付金额
          </div>
          <div class="cell_value red-color">
            ¥ {{ details.zje }}
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="title">
          选择支付方式
        </div>
        <div class="cell_pay">
          <van-radio-group v-model="wxChecked" checked-color="#09BB07">
            <div class="pay_cell" @click="wxChecked == '1'">
              <div class="cell_title">
                <van-icon name="wechat-pay" class="icon green-color size32" />
                微信支付
              </div>
              <van-radio name="1" />
            </div>
            <div
              v-if="orderTypeKey !== '14'"
              class="pay_cell"
              @click="wxChecked == '2'"
            >
              <div class="cell_title">
                <img src="../../../assets/img/gjc_yibao.png" class="icon_img">
                医保支付
              </div>
              <van-radio name="2" />
            </div>
          </van-radio-group>
        </div>
      </div>
      <div class="btn-box">
        <!-- <div class="box_title">
          温馨提示：
        </div> -->
        <div class="box_cont" v-html="hospInfo.content" />
      </div>
    </div>
    <div>
      <GjcPay
        v-if="wxChecked == '1'"
        :price="details.zje"
        :text="text"
        :order-id="details.orderId"
        :order-type-key="orderTypeKey"
        @success="onPaySuccess"
      />
      <GjcYBPay
        v-if="wxChecked == '2'"
        :price="details.zje"
        :order-id="details.orderId"
        :order-type-key="orderTypeKey"
        @success="onPaySuccess"
      />
    </div>
  </div>
  <div v-else class="page-index">
    <div class="hd">
      <div class="info">
        <div class="info-hd">
          付款给
        </div>
        <div class="info-bd">
          {{ ybDic.yymc }}
        </div>
      </div>
      <div class="info-icon">
        <img
          class="info-icon-img"
          src="../../../assets/img/gjc_info.png"
          alt=""
        >
      </div>
      <div class="bg" />
    </div>
    <div class="bd">
      <div class="box">
        <div class="box-hd">
          <div class="box-hd-label">
            费用总额
          </div>
          <div class="box-hd-value">
            {{ ybDic.totalFee }}元
          </div>
        </div>
        <div class="box-bd">
          <div class="box-item">
            <div class="box-bd-label">
              医保基金支付
            </div>
            <div class="box-bd-value">
              {{ ybDic.ybJjhj }} 元
            </div>
          </div>
          <div class="box-item">
            <div class="box-bd-label">
              个人帐户支付
            </div>
            <div class="box-bd-value">
              {{ ybDic.acctPay }} 元
            </div>
          </div>
          <!-- <div class="box-item">
                        <div class="box-bd-label">其他抵扣金额</div>
                        <div class="box-bd-value">233元</div>
                    </div> -->
        </div>
        <div class="box-ft">
          <div class="box-ft-label">
            现金支付
          </div>
          <div class="box-ft-value">
            {{ ybDic.psnCashPay }}元
          </div>
        </div>
        <!-- <div class="box-append" @click="handleActionsheet">
                    查看明细
                </div> -->
      </div>
      <div class="bd-append">
        <i class="bd-append-icon" />
        <div class="bd-append-text">
          医保移动支付
        </div>
      </div>
    </div>
    <div class="ft">
      <div class="pay">
        <div class="pay-label">
          您还需支付：
        </div>
        <div class="pay-value">
          ¥{{ ybDic.payAmount }}
        </div>
      </div>
      <div :class="isPay ? 'btn' : 'btn-gray'" @click="debounceCreateYbPay">
        去支付
      </div>
    </div>
    <!-- <div class="actionsheet" v-show="actionsheetVisible">
            <div class="actionsheet-mask" @click="handleActionsheet"></div>
            <div class="actionsheet-panel">
                <div class="actionsheet-hd">
                    <div class="actionsheet-hd-tt">处方明细</div>
                    <i class="actionsheet-icon-close" @click="handleActionsheet"></i>
                </div>
                <div class="actionsheet-bd">
                    <div class="actionsheet-box" v-for="item in prescriptionList" :key="item.title">
                        <div class="actionsheet-box-hd">{{ item.title }}</div>
                        <div class="actionsheet-box-bd">
                            <div class="actionsheet-box-item" v-for="boxItem in item.list" :key="boxItem.label">
                                <div class="actionsheet-box-item-label-wrap">
                                    <div class="actionsheet-box-item-label">{{ boxItem.label }}</div>
                                    <div class="actionsheet-box-item-sublabel">{{ boxItem.subLabel }}</div>
                                </div>
                                <div class="actionsheet-box-item-value" :class="[boxItem.highlight ? 'em' : '']">
                                    {{ boxItem.value }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <van-dialog v-model="show" :title="''" show-confirm-button theme="round-button" confirm-button-text="重新授权" confirm-button-color="#3A8AE5" :before-close="clickConfirm">
      <div style="color: #f95776 !important;padding: 20px;">
        {{ errMsg }}
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { gjcModel } from "../../../api/gjcApi"
import { WX_CONST } from "@/utils/constant"
import { Toast } from "vant"
import GjcPay from "@/components/pay/gjc-pay"
import GjcYBPay from "@/components/pay/gjc-ybpay"
import { debounce } from '../../../utils/index'
export default {
  name: "YbPay",
  components: { GjcPay, GjcYBPay },
  data() {
    return {
      details: {},
      orderId: -1,
      wxChecked: "1", //默认1:微信支付 2:医保支付
      text: "",
      //1挂号单2处方单11挂缴查门诊缴费12挂缴查体检缴费13挂缴查预约挂号缴费14挂缴查预缴金缴费
      orderTypeKey: "",
      prescriptionList: [
        {
          title: "就诊信息",
          list: [
            { label: "门诊类别", value: "门(急)诊" },
            { label: "门诊科室", value: "普通内科" },
            { label: "医生姓名", value: "张三" },
            { label: "处方时间", value: "2021/06/08 14:54:00" },
            { label: "费用总额", value: "368.50元", highlight: true }
          ]
        },
        {
          title: "诊断信息",
          list: [
            { label: "诊断名称", value: "外伤肿胀" },
            { label: "诊断编号", value: "E3D.25" }
          ]
        },
        // {
        //   title: '特殊信息',
        //   list: [
        //     { label: '病情名称', value: '高血压' },
        //     { label: '病情编号', value: '2220003495858' },
        //   ]
        // },
        {
          title: "费用信息",
          list: [
            { label: "万通胫骨贴*1", subLabel: "8g/片/3", value: "37.80元" },
            { label: "阿莫西林*1", subLabel: "8g/片/3", value: "7.80元" }
          ]
        }
        // {
        //   title: '其他抵扣金额',
        //   list: [
        //     { label: '住院押金抵扣', value: '50元' },
        //     { label: '医院负担金额抵扣', value: '50元' },
        //   ]
        // },
      ],
      actionsheetVisible: false,
      argumentData: {
        retCode:'0'
      }, //url带的参数
      severDic: {}, //服务数据
      ybDic: {}, //获取数据
      isPay: false,
      show:false,
      errMsg: '',
      loading: false,
      hospInfo:{}
    }
  },
  mounted() {
    // let url = 'https://user-szlsnk.blueunitech.cn/?authCode=364166433404570800&retCode=0#/ybPay?orderId=5&authCode=364166433404570800&retCode=0' 150403
    let url = window.location.href //获取当前url
    let cs = url.split("ybPay?")[1] //获取?之后的参数字符串
    let cs_arr = cs.split("&") //参数字符串分割为数组
    // 遍历数组，将键值对保存到params对象中
    cs_arr.forEach((pair) => {
      let keyValue = pair.split("=")
      this.argumentData[keyValue[0]] = keyValue[1]
    })
    console.log("参数-------", this.argumentData)
    if (this.argumentData.retCode == "0") {
      Toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      })
      this.isPay = false
      this.getPayAuthSelfInfoData()
    } else {
      this.orderId = this.argumentData.orderId
    //   this.orderTypeKey = this.$route.query.type || "";
    //   if (this.orderTypeKey !== "13") {
    //     this.text = "";
    //   }
      this.mzPayDetail()
    }
  },
  methods: {
    gjcDepartDesc(){
          gjcModel.gjcDepartDesc('ZFXZ').then((result) => {
                this.hospInfo = result.data
            })
        },
    mzPayDetail() {
      gjcModel.mzPayDetail(this.orderId).then((res) => {
        this.details = res.data || {}
        this.orderTypeKey = String(res.data.businessType) || ''
        if (this.orderTypeKey !== '13') {
          this.text = "" 
        }
      })
    },
    onPaySuccess() {
      this.$router.push({
        path: "/paySuccess",
        query: {
          orderId: this.orderId
        }
      })
    },
    //获取信息服务
    getPayAuthSelfInfoData() {
      gjcModel
        .getPayAuthSelfInfoData(this.argumentData.authCode)
        .then((res) => {
          console.log("获取信息服务----", res)
          this.severDic = res.data
          this.getHisYbBudgetData()
        })
        .catch((err) => {
          this.show = true
          console.log('获取信息服务',err)
          this.errMsg = err.msg
          setTimeout(() => {
                Toast.clear() 
            }, 1000) 
        })
    },
    //获取医保数据
    getHisYbBudgetData() {
      const params = {
        orderId: this.argumentData.orderId,
        payAuthNo: this.severDic.payAuthNo,
        uldLatlnt: this.severDic.uldLatlnt
      }
      gjcModel
        .getHisYbBudgetData(params)
        .then((res) => {
          console.log("获取医保数据-------", res)
          this.ybDic = res.data
          this.isPay = true
          Toast.clear()
        })
        .catch((err) => {
          this.show = true
          this.errMsg = err.msg
          console.log('获取医保数据',err)
          setTimeout(() => {
            Toast.clear() 
          }, 1000) 

        })
    },
    debounceCreateYbPay:debounce(function(){
      this.createYbPay()
    },500,true),
    //创建医保支付
    createYbPay() {
      if (!this.isPay) {
        return
      }
      Toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      })
      const params = {
        appid: WX_CONST.appid,
        businessOrderId: this.argumentData.orderId,
        ybContent: {
          payAuthNo: this.severDic.payAuthNo,
          setlLatlnt: this.severDic.uldLatlnt
        }
      }
      gjcModel.getCreateYbPayData(params).then((res) => {
        Toast.clear()
        window.location.href = res.data.payUrl
      }).catch(() => {
        setTimeout(() => {
          Toast.clear() 
        }, 1000) 
      })
    },
    handleActionsheet() {
      this.actionsheetVisible = !this.actionsheetVisible
    },
    clickConfirm(action) {
      if (action === "confirm") {
        this.$router.push({
          path: "/orderPay",
          query:{
            id: this.argumentData.orderId
          }
        })
        this.show = false
      }
    }
  }
}
</script>

<style  lang='less' scoped>
@import "../../../assets/styles/variable.less";

.orderpay_page {
    background-color: #f1f1f1;
}

.red-box {
    width: 100%;
    height: 168px;
    background: #1e80ff;

    p {
        color: #ffffff;
        margin: 0;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 88px;
    }
}

.page-box {
    padding: 0 32px 50px;
    box-sizing: border-box;
    position: relative;
    top: -80px;

    .card-box {
        width: 100%;
        background: #fff;
        border-radius: 12px;
        padding: 30px 32px;
        margin-bottom: 24px;
        box-sizing: border-box;
        z-index: 2;

        .tit {
            width: 100%;
            color: @dark-font-color;
            padding-bottom: 20px;
            text-align: center;
            font-size: 28px;
            font-weight: bold;
        }

        .title {
            font-size: 32px;
            font-weight: bold;
            color: @dark-font-color;
        }

        .radio-group {
            display: flex;
            justify-content: space-between;

            .radio-btn {
                width: 176px;
                height: 48px;
                line-height: 48px;
                color: #999999;
                text-align: center;
                border-radius: 24px;
                border: 1px solid #999999;

                &.active {
                    color: @theme-color;
                    border: 1px solid @theme-color;
                }
            }
        }

        .cell {
            position: relative;
            display: flex;
            box-sizing: border-box;
            width: 100%;
            padding: 16px 0;
            font-weight: 400;
            overflow: hidden;
            color: @dark-font-color;
            font-size: 28px;
            line-height: 40px;
        }

        .cell_title,
        .cell_value {
            flex: 1 1 auto;
        }

        .cell_value {
            overflow: hidden;
            text-align: right;
        }
    }

    .icon {
        position: relative;
        top: 8px;
        margin-left: 15px;
        margin-right: 15px;
        width: 30px;
        height: 18px;
    }
}

.fl-right {
    float: right;
    margin-top: 6px;
}

.cell_pay {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 0 0 0;
    font-weight: 400;
    overflow: hidden;
    color: @dark-font-color;
    font-size: 28px;
    line-height: 40px;

    .pay_cell {
        display: flex;
        margin-top:30px;
        justify-content: space-between;
    }

    .cell_title,
    .cell_value {
        flex: 1 1 auto;
    }
}
.box_title{
  padding: 20px;
  font-size: 30px;
  font-weight: bold;
  color: red;
}

.box_cont{
  padding: 0 20px;
  font-size: 26px;
  color: #666;
}

.icon_img {
    width: 60px;
    height: 18px;
}

.page-index {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hd {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #fff;
  padding: 64px 40px 40px 40px;
  box-sizing: border-box;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 340px;
  background-color: #1e80ff;
  border-radius: 0 0 30px 30px;
  z-index: 0;
}

.info {
  position: relative;
  z-index: 1;
  color: #fff;
}

.info-hd {
  font-size: 28px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.6);
}

.info-bd {
  font-size: 36px;
  line-height: 40px;
  margin-top: 3px;
  font-weight: bold;
}

.info-icon {
  position: relative;
  z-index: 1;
  width: 80px;
  height: 80px;
}

.info-icon-img {
  display: block;
  width: 100%;
  height: 100%;
}

.bd {
  flex: 1;
  position: relative;
  z-index: 1;
}

.box {
  border-radius: 16px;
  background-color: #fff;
  margin: 0 40px;
  padding: 0 40px;
  padding-bottom: 34px;
  box-shadow: 0 6px 20px #e6e6e6;
}

.box-hd {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 24px 0 18px;
  color: #606266;
  font-size: 32px;
  line-height: 40px;
}

.box-hd:before {
  content: "";
  position: absolute;
  left: -8px;
  bottom: 0;
  right: -8px;
  border-bottom: 1px dashed #d8d8d8;
  transform: scaleY(0.5);
}

.box-hd-label {
  font-weight: bold;
}

.box-hd-value {
  font-weight: bold;
}

.box-bd {
  padding-top: 19px;
}

.box-item {
  display: flex;
  justify-content: space-between;
  color: #909399;
  font-size: 28px;
  line-height: 40px;
  padding-bottom: 10px;
}

.box-item:last-child {
  padding-bottom: 0;
}

.box-ft {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 17px;
  font-size: 32px;
  color: #1e80ff;
}

.box-ft-label {
  font-weight: bold;
}

.box-ft-value {
  font-weight: bold;
}

.box-append {
  position: relative;
  font-size: 28px;
  line-height: 40px;
  color: #606266;
  text-align: center;
  padding: 20px 0 3px;
}

.box-append:before {
  content: "";
  position: absolute;
  left: -8px;
  top: 0;
  right: -8px;
  border-top: 1px dashed #d8d8d8;
  transform: scaleY(0.5);
}

.bd-append {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.bd-append-icon {
  display: block;
  width: 80px;
  height: 24px;
  background: url("../../../assets/img/gjc_yibao.png") center center no-repeat;
  background-size: 100% 100%;
  margin-right: 8px;
}

.bd-append-text {
  font-size: 28px;
  line-height: 40px;
  color: #909399;
  font-weight: bold;
}

.ft {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: calc(14px + constant(safe-area-inset-bottom));
  padding-bottom: calc(14px + env(safe-area-inset-bottom));
}

.pay {
  display: flex;
}

.pay-label {
  font-size: 32px;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 5px;
}

.pay-value {
  font-size: 44px;
  line-height: 48px;
  color: #1e80ff;
  font-weight: bold;
}

.btn {
  font-size: 36px;
  font-weight: bold;
  line-height: 48px;
  color: #fff;
  background-color: #0d7cff;
  padding: 10px 23px;
  border-radius: 48px;
}
.btn-gray {
  font-size: 36px;
  font-weight: bold;
  line-height: 48px;
  color: #909399;
  background-color: #e6e6e6;
  padding: 10px 23px;
  border-radius: 48px;
}

.ft:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: scaleY(0.5);
}

.actionsheet-mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.actionsheet-panel {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 1001;
  border-radius: 16px 16px 0 0;
  max-height: 93vh;
  overflow-y: auto;
}

.actionsheet-hd {
  display: flex;
  justify-content: space-between;
  padding: 27px 20px 17px;
}

.actionsheet-hd-tt {
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #303133;
}

.actionsheet-icon-close {
  display: block;
  width: 24px;
  height: 24px;
  background: url("../../../assets/img/gjc_close.png") center center no-repeat;
  background-size: 100% 100%;
}

.actionsheet-box {
  border: 1px solid #f8f8f8;
  border-radius: 6px;
  margin: 10px;
}

.actionsheet-box-hd {
  position: relative;
  font-size: 32px;
  line-height: 44px;
  font-weight: bold;
  color: #303133;
  margin: 25px 0 17px;
  padding: 0 20px;
}

.actionsheet-box-hd:before {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  width: 4px;
  height: 17px;
  transform: translateY(-50%);
  background-color: #3b71eb;
}

.actionsheet-box-bd {
  padding: 20px;
  border-top: 1px solid #f8f8f8;
}

.actionsheet-box-item {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  line-height: 40px;
}

.actionsheet-box-item + .actionsheet-box-item {
  margin-top: 12px;
}

.actionsheet-box-item-label {
  color: #606266;
}

.actionsheet-box-item-sublabel {
  color: #909399;
}

.actionsheet-box-item-value {
  color: #606266;
}

.actionsheet-box-item-value.em {
  color: #3b71eb;
}
</style>