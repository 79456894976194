<!--
 * @paga :在线缴费
 * @Author: your name
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2024-12-16 17:39:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\payFees.vue
-->
<template>
  <div class="pay_fees_page">
    <div class="page_top">
      <van-sticky offset-top="50px">
        <div v-if="tabsType == 2 && patientDict && patientDict.xm" class="one_item" @click="gotoPatientList">
          <div class="item_name flex-center">
            {{ patientDict.xm }}
            <span class="item_zyh">住院号: {{ zyh }}</span>
            <span class="item_zyh">总费用: {{ zyTotalFee }} {{ zyTotalFee?'元':'' }}</span>
          </div>
          <div class="item_right">
            <van-icon name="arrow" size="18" />
          </div>
        </div>
        <patient-header v-else :patient-dict="patientDict" />
        <van-tabs v-model="tabsType" type="line" @click="seleteTabsAction">
          <van-tab v-for="(item, index) in tabs" :key="index" :title="item.name" />
        </van-tabs>
      </van-sticky>
    </div>
    <div v-if="selectTabIndex === '1'" class="appoint-list">
      <div v-for="(item, index) in dataSource" :key="index" class="list-item">
        <div class="item-cell">
          <div class="item-title">
            {{ item.ddlxmc }}
          </div>
        </div>
        <div class="item-cell">
          <div class="cell-part">
            <div class="cell-name">
              科室名称:
            </div>
            <div class="cell-value">
              {{ item.ksmc }}
            </div>
          </div>
          <div class="cell-part">
            <div class="cell-name">
              医生姓名:
            </div>
            <div class="cell-value">
              {{ item.ysxm }}
            </div>
          </div>
        </div>
        <div class="item-cell">
          <div class="cell-part">
            <div class="cell-name">
              日期:
            </div>
            <div class="cell-value">
              {{ dateSubstring(item.createTime) }}
            </div>
          </div>
          <div class="cell-part">
            <div class="cell-name">
              金额:
            </div>
            <div class="cell-value">
              {{ item.zje }}元
            </div>
          </div>
        </div>
        <div class="item-btns">
          <van-button class="cell-btn" plain type="primary" round size="small" @click="debounceGotoAppointPay(item)">
            去支付
          </van-button>
        </div>
      </div>
      <no-found v-if="!dataSource || dataSource.length <= 0" />
      <div class="appoint-bottom">
        <div class="bottom-btn" @click="seeRegisterList('3')">
          查看挂号缴费记录
        </div>
      </div>
    </div>
    <div v-else-if="selectTabIndex === '2'" class="outpatient-list">
      <div v-for="(item, index) in dataSource" :key="index" class="outpatient-list-item">
        <div v-if="item.xmlx === '1'">
          <div class="drug-section">
            <div class="drug-section-title flex-1">
              西药费用
            </div>
            <div class="drug-section-zj flex-1 flex-center">
              <span class="drug-section-price"> {{ item.zje }}</span>元
            </div>
            <div class="drug-section-detail flex-1 flex-end">
              <div @click="onChangeDrugListShowStatus(item)">
                明细<van-icon class="drug-section-arrow" :name="item.show ? 'arrow-up' : 'arrow-down'" />
              </div>
            </div>
          </div>
          <div v-if="item.show" class="drug-list">
            <div v-for="(drug, xIndex) in item.detailItemList" :key="xIndex" class="drug-item">
              <div class="flex-1">
                {{ drug.xmmc }}
              </div>
              <div class="flex-1 flex-center">
                {{ drug.dj }}元
              </div>
              <div class="flex-1 flex-end">
                {{ drug.sl }}{{ drug.dw }}
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="item.xmlx === '3'">
          <div class="drug-section">
            <div class="title">
              中药费用
            </div>
            <div class="drug-section-zj">
              <span class="drug-section-price"> {{ item.zje }}</span>元
            </div>
            <div class="drug-section-detail flex-end">
              <div @click="onChangeDrugListShowStatus(item)">
                明细<van-icon class="drug-section-arrow" :name="item.show ? 'arrow-up' : 'arrow-down'" />
              </div>
            </div>
          </div>
          <div v-if="item.show" class="drug-list">
            <div v-for="(drug, yIndex) in item.detailItemList" :key="yIndex" class="drug-item">
              <div class="flex-1">
                {{ drug.xmmc }}
              </div>
              <div class="flex-1 flex-center">
                {{ drug.dj }}元
              </div>
              <div class="flex-1 flex-end">
                {{ drug.sl }}{{ drug.dw }}
              </div>
            </div>
          </div>
          <div v-if="item.show" class="drug-item-bottom">
            总计：{{ item.detailItemList[0].cfts }}贴
          </div>
        </div>
        <div v-else>
          <div class="drug-section">
            <div class="drug-section-title flex-1">
              门诊收费({{ item.feeTypeName }})
            </div>
            <div class="drug-section-zj flex-1 flex-center">
              <span class="drug-section-price"> {{ item.zje }}</span>元
            </div>
            <div class="drug-section-detail flex-1 flex-end" />
          </div>
          <div class="drug-list">
            <div v-for="(drug, zIndex) in item.detailItemList" :key="zIndex" class="drug-item">
              <div class="flex-1">
                {{ drug.xmmc }}
              </div>
              <div class="flex-1 flex-center">
                {{ drug.dj }}元
              </div>
              <div class="flex-1 flex-end">
                {{ drug.sl }}{{ drug.dw }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="dataSource.length > 0" class="outpatient-bottom">
        <div class="price">
          合计：<span class="red-color">{{ totalPrice }}</span>
        </div>
        <van-button class="cell-btn outpatient-pay-btn" plain type="primary" round size="small" @click="debounceCreateOutPatientOrder">
          立即支付
        </van-button>
      </div>
      <no-found v-if="!dataSource || dataSource.length <= 0" />
      <div class="appoint-bottom">
        <div class="bottom-btn" @click="seeRegisterList('1')">
          查看门诊缴费记录
        </div>
      </div>
    </div>
    <div v-else class="hosptial-cost-list">
      <div v-for="(item, index) in dataSource" :key="index" class="list-item">
        <div class="item-cell">
          <div class="item-title">
            住院预缴
          </div>
        </div>
        <div class="item-cell">
          <div class="cell-part">
            <div class="cell-name">
              住院号:
            </div>
            <div class="cell-value">
              {{ item.admissionNo }}
            </div>
          </div>
          <div class="cell-part">
            <div class="cell-name">
              科室名称:
            </div>
            <div class="cell-value">
              {{ item.currentDeptName }}
            </div>
          </div>
        </div>
        <div class="item-cell">
          <div class="cell-part">
            <div class="cell-name">
              住院总金额:
            </div>
            <div class="cell-value">
              {{ item.totalCost }}元
            </div>
          </div>
          <div class="cell-part">
            <div class="cell-name">
              已预缴:
            </div>
            <div class="cell-value">
              {{ item.deposit }}元
            </div>
          </div>
        </div>
        <div class="item-cell">
          <div class="cell-part">
            <div class="cell-name">
              押金余额:
            </div>
            <div class="cell-value">
              {{ item.depositBalance }}元
            </div>
          </div>
        </div>
        <div class="hosptial-cost-btns flex-center">
          <van-button class="cell-btn" plain type="primary" round size="small" 
                      @click="debounceGotoHosptialCostDetail"
          >
            立即预缴
          </van-button>
        </div>
      </div>
      <no-found v-if="!dataSource || dataSource.length <= 0" />
      <div class="appoint-bottom">
        <div class="bottom-btn" @click="seeRegisterList('4')">
          查看住院缴费记录
        </div>
      </div>
    </div>
    <!-- <no-found v-if="!dataSource || dataSource.length <= 0" /> -->
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
import NoFound from '../../../components/nofound/NoFound.vue'
import { debounce } from "../../../utils/index"
import { setItem } from "@/utils/cache"
import { Toast } from 'vant'
export default {
  name: 'PayFees',
  components:{NoFound},
  data() {
    return {
      patientDict: {},//就诊人字典
      patientCardId: '',
      patientInfoId: '',
      tabsType: '0',
      tabs: [{
        name: '挂号缴费',
        type: '1',
        id: ''
      }, {
        name: '门诊缴费',
        type: '2',
        id: ''
      }, {
        name: '住院预缴',
        type: '3',
        id: ''
      }],
      selectTabIndex: '1',
      //门诊总金额
      totalPrice: 0,
      //住院总金额
      zyh:'',
      zyTotalFee: null,
      dataSource: []
    }
  },
  // 创建防抖函数，立即执行第一次点击
  created() {
  },
  async mounted() {
    let type = this.$route.query.type
    if (type) {
      this.selectTabIndex = type
      this.tabsType = this.selectTabIndex - 1
    }
    await this.getHomePatientListData()
  },
  beforeDestroy() {
    Toast.clear()
  },
  methods: {
    gotoPatientList() {
      this.$router.push('/patientList')
    },
    async getHomePatientListData() {
      const params = {
        yydm: "",
        page: "",
        pageSize: ""
      }
      const res = await gjcModel.getHomePatientListData(params)
      if (res &&  res.code == 200)  {
        this.patientDict = res.data || {}
        console.log(this.patientDict,'this.patientDict')
        this.$store.commit("user/setPatient", JSON.stringify(res.data))
         if (this.patientDict && this.patientDict.patientId) {
          this.getList()
        }else{
          this.$toast({
            message: "请先添加就诊人"
          })
          setItem('redirect', '/payFees?type=' + this.selectTabIndex)
          this.$nextTick(() => {
            this.$router.push("/patientList")
          })
        }
      }
    },
    async getList() {
      Toast.loading({
        mask: true,
        forbidClick: true,
        duration: 0,
        message: "加载中..."
      })
      const params = {
        yydm: '', //医院代码
        patId: this.patientDict.patientId,
        cardId: this.patientDict?.patientCardList[0].patientCardId,
        patientCardId: this.patientDict?.patientCardList[0].patientCardId,
        patientInfoId: this.patientDict.patientId,
        patientId: this.patientDict.patientId
      }
      let method = ""
      this.dataSource = []
      switch (this.selectTabIndex) {
        case '1':
          method = 'appointOrderList'
          break
        case '2':
          method = 'getOnlinePayListData'
          break
        default:
          method = 'gjcZyOnLinePay'
          break
      }
      const res = await gjcModel[method](params)
      if (res.data && this.selectTabIndex == '1') {
        this.dataSource = res.data || []
      } else if (res.data && this.selectTabIndex == '2') {
        this.dataSource = res.data?.detailList || []
        if (this.dataSource.length > 0) {
          this.clacTotalPrice()
        }
      }else {
        if (res.data) {
          this.dataSource.push(res.data || {})
          this.zyh = res.data.admissionNo || ''
          this.zyTotalFee = res.data.totalCost || null
        }else{
          this.zyh = ''
          this.zyTotalFee = null
        }
      }
      this.$nextTick(() => {
        Toast.clear()
      })
    },
    clacTotalPrice() {
      let priceArr = []
      this.dataSource.forEach(item => {
        priceArr.push(item.zje)
      })
      gjcModel.feeCalculation({ totalPrice: (priceArr) }).then((result) => {
        this.totalPrice = result.data
      })
    },
    //获取详情
    getDetail(item) {
      this.$router.push({
        path: '/gjcPayFeesDetail',
        query: {
          id: item.id
        }
      })
    },
    //切换类型
    seleteTabsAction(name) {
      let item = this.tabs[name]
      this.selectTabIndex = item.type
      this.totalPrice = 0
      this.getList()
    },
    dateSubstring(dateStr) {
      if (!dateStr && dateStr.length < 11) {
        return ""
      }
      return dateStr.substring(0, 10)
    },
    debounceGotoAppointPay:debounce(function(item){
      this.gotoAppointPay(item)
    },500,true),
    /**
     * 跳转到指定支付页面
     * @param {Object} item - 包含订单信息的对象
     * @param {string} item.orderId - 订单ID
     * @description 该方法用于将用户导航到订单支付页面，并传递订单ID和支付类型作为查询参数。
     */
    gotoAppointPay(item){
      this.$router.push({
        path: '/orderPay',
        query: {
          id: item.orderId,
          type: '13'
        }
      })
    },
    debounceGotoHosptialCostDetail:debounce(function(){
      this.gotoHosptialCostDetail()
    },500,true),
    gotoHosptialCostDetail() {
      this.$router.push({
        path: '/hosptialCostDetail'
      })
    },
    debounceCreateOutPatientOrder:debounce(function(){
      this.createOutPatientOrder()
    },500,true),
    createOutPatientOrder() {
      let params = {
        patId: this.patientDict.patientId,
        cardId: this.patientDict.patientCardList[0].patientCardId,
        detailList: this.dataSource
      }
      gjcModel.addMzOrderInfo(params).then((result) => {
        if (result.code === 200) {
          this.$router.push({
            path: '/orderPay',
            //支付类型 1挂号单2处方单11挂缴查门诊缴费13挂缴查预约挂号缴费14挂缴查预缴金缴费
            query: {
              id: result.data.orderId,
              type: '11'
            }
          })
        }
      })
    },
    onChangeDrugListShowStatus(item) {
      this.$set(item, 'show', !item.show)
    },
    //查看缴费记录
  seeRegisterList(type){
      this.$router.push({
        path: '/registerPayList',
        query: {
          type: type
        }
      })
    }
  }
}
</script>
<style scoped lang='less'>
.pay_fees_page {
  background-color: #f5f5f5;
  font-size: 24px;
}

.page_top {
  border-bottom: 1px solid #f5f5f5;
  .one_item {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 24px ;
    color: white;
    background-color: #1e80ff;
    border-bottom: 1px solid #f5f5f5;
    font-size: 28px;
    .item_zyh{
      margin-left: 12px;
    }
  }
}

.appoint-list, .outpatient-list, .hosptial-cost-list{
  padding: 24px 32px;
}

.list-item {
  padding: 12px 24px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #f5f5f5;

  .item-title {
    font-size: 28px;
    color: #333333;
    font-weight: bold;
  }

  .item-cell {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .cell-part {
      display: flex;
      flex: 1;
      align-items: center;

      .cell-name {}

      .cell-value {
        margin-left: 12px;
      }
    }
  }
}

.appoint-bottom{
  display: flex;
  justify-content: center;
}
.bottom-btn{
  margin: 24px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #f5f5f5;
  margin-bottom: 24px;
  width: 260px;
  text-align: center;
  font-size: 30px;
  color: #666;
}

.outpatient-list-item {
  background: #fff;
  border-radius: 12px;
  border: 1px solid #f5f5f5;
  overflow: hidden;
}

.item-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cell-btn {
  padding: 8px 24px;
}

.outpatient-list {
  margin-bottom: calc(env(safe-area-inset-bottom) + 120px);
}

.outpatient-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: calc(env(safe-area-inset-bottom) + 24px);
  padding-top: 24px;
  background: #fff;
}

.outpatient-pay-btn {
  margin-left: 24px;
  margin-right: 32px;
}

.drug-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.drug-list {
  background: #f5f5f5;
  margin: 0 24px 12px;
  border-radius: 12px;
}

.drug-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
}

.drug-item-bottom {
  padding: 0 24px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.drug-section-price {
  color: red;
}

.drug-section-arrow {
  margin-left: 6px;
}

.flex-1 {
  flex: 1;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hosptial-cost-btns {
  padding: 24px;
}
</style>
