/*
 * @Date: 2024-10-09 09:10:02
 * @LastEditTime: 2024-12-13 15:48:20
 * @Description: 
 * @FilePath: \szlsnk-user-mp\src\blueCross-ui.js
 */
import Vue from 'vue'

import DefaultImage from './components/default-image/DefaultImage'
import appointmentItem from './components/appointment/appointmentItem'
import gjcAppointmentItem from './components/appointment/gjcAppointmentItem'
import consultationItem from './components/consultation/consultationItem'
import prescriptionItem from './components/prescription/prescriptionItem.vue'
import drugItem from './components/drug/drugItem'
import zyDrugItem from './components/drug/zyDrugItem'
import doctorList from './components/doctor/doctorList'
import LoadMore from './components/load-more/LoadMore'
import pageRefreshLoad from './components/pageRefreshLoad/pageRefreshLoad'
import tabs from './components/tabs/tabs'
import orderItem from './components/order/orderItem'
import patientSelect from './components/patient/patientSelect.vue'
import patientHeader from './components/patient/patientHeader'
import AddressItem from './components/category/addressItem'
import priceTable from './components/priceTable/priceTable'
import gjcPriceCell from './components/priceTable/gjcPriceCell'

const components = [
  DefaultImage,
  appointmentItem,
  gjcAppointmentItem,
  consultationItem,
  prescriptionItem,
  drugItem,
  zyDrugItem,
  doctorList,
  LoadMore,
  pageRefreshLoad,
  tabs,
  orderItem,
  patientSelect,
  patientHeader,
  AddressItem,
  priceTable,
  gjcPriceCell
]
components.map(component => {
  Vue.component(component.name, component)
})
