<!--
 * @Author: 张阳帅
 * @Date: 2024-09-25 09:52:37
 * @LastEditTime: 2024-12-18 09:28:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\patient\patientList.vue
-->
<template>
  <div class="patientlist_page">
    <div class="page_bottom">
      <div class="bottom_add" @click="getPatientAddAction">
        <img class="add-image" src="../../../assets/img/add_hollow.png">
        添加就诊人
      </div>
    </div>
    <div class="page_main">
      <div v-for="(item,index) in patientList" :key="index" class="main_item" @click="onSelectDefaultPatient(item)">
        <div class="item_info">
          <img class="info_img" src="https://lanzhoutech.oss-cn-shanghai.aliyuncs.com/naokeWebImages/h5/public_user_info.png" alt="">
          <div class="info_name">
            <div class="name_xm">
              <a v-if="item.sfmr=='1'" class="xm_sfmr">当前</a>
              {{ item.xm }} 
            </div>
            <div class="name_zjh">
              手机号：{{ encryptPhoneNum(item.sjh) }}
            </div>
            <div class="name_zjh">
              证件号：{{ encryptIdCordNum(item.zjh) }}
            </div>
          </div>
          <div class="item_delete">
            <!-- <van-icon name="delete" size="23" color="#1e8dff" @click.stop="deletePatientAction(item)" /> -->
            <van-icon class="delete_edit" name="replay" size="20" color="#1e8dff" @click.stop="refreshPatientCard(item)" />
            <van-icon class="delete_edit" name="edit" size="22" color="#1e8dff" @click.stop="editPatientAction(item)" />
            <van-icon name="delete-o" size="22" color="#1e8dff" @click.stop="deletePatientAction(item)" />
          </div>
        </div>
        <div v-if="item.patientCard && item.patientCard.length > 0 ">
          <div v-for="(cardItem, cardIndex) in item.patientCard " :key="'cardItem' + cardIndex" class="item_card" @click.stop="onSelectPatientAndCard(item,cardItem)">
            <div class="card-left">
              <div class="card_xm">
                {{ cardItem.jzklxName }}
              </div>
              <div class="card_xm ml-12">
                {{ cardItem.jzkh }}
              </div>
              <a v-if="cardItem.sfmr==='1'" class="xm_sfmr">默认</a>
            </div>
            <div class="card-right">
              <van-icon :name="cardItem.sfmr==='1'?'passed':'circle'" size="20" :color=" cardItem.sfmr==='1'?'#1E8DFF':'#9a9a9a'" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="patientList.length==0">
        <van-empty description="暂无就诊人" />
      </div> 
    </div>
  </div>
</template>
<script>
import { gjcModel } from '../../../api/gjcApi'
import { encryptPhoneNum, encryptIdCordNum} from '@/utils'
import { Toast } from 'vant'
export default {
    data(){
        return{
            page:1, //页码
            patientList:[] //就诊人列表
        }
    },
    mounted() {
        this.getPatientListData()
    },
    beforeDestroy() {
        Toast.clear()
    },
    methods:{
        getPatientListData(){
            Toast.loading({
                mask: true,
                duration: 0,
                message: "加载中..."
            })
            const params = {
                yydm: '',
                page: this.page,
                pageSize:'10'
            }
            gjcModel.getPatientListData(params).then(res=>{
                this.patientList = res.data
                Toast.clear()
            }).catch(()=>{
                setTimeout(() => {
                  Toast.clear() 
                }, 1000) 
            })
        },
        //手机号码脱敏
        encryptPhoneNum(num) {
            return num ? encryptPhoneNum(num) : ''
        },
        //身份证号码脱敏
        encryptIdCordNum(num) {
            return num ? encryptIdCordNum(num) : ''
        },
        //删除就诊人
        deletePatientAction(item){
            // console.log('删除就诊人', item)
            this.$dialog.alert({
                title: '删除提示',
                message: '确认删除就诊人[ '+item.xm+' ]吗?',
                showCancelButton: true
            }).then(() => {
                this.getDeletePatientData(item.id)
            }).catch(() => {
                this.$dialog.close()
            })
        },
        //刷新就诊人
        refreshPatientCard(item) {
            gjcModel.refreshCard(item.id).then(()=>{
                this.$toast('刷新成功')
                this.getPatientListData()
            })
        },
        //编辑就诊人
        editPatientAction(item){
            console.log('编辑就诊人', item)
            this.$router.push({
                path: '/patientEdit',
                query: {
                    id: item.id
                }
            })
        },
        //删除就诊人
        getDeletePatientData(id) {
            gjcModel.getDeletePatientData(id).then(()=>{
                this.$toast('删除成功')
                this.getPatientListData()
            })
        },
        onSelectPatientAndCard(item,cardItem) {
            if (item.sfmr ==='1' &&  cardItem.sfmr === '1') {
                this.$router.back()
            }
            this.$dialog.confirm({
                title: '切换就诊人/就诊卡',
                message: `确认切换</br>就诊人:[ ${item.xm} ],</br>就诊卡: [ ${cardItem.jzkh} ]吗?`,
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                confirmButtonColor: '#1E8DFF'
            }).then(() => {
                this.getSetDefaultPatientData(item,cardItem)
            }).catch(() => {
                this.$dialog.close()
            })
        },
        onSelectDefaultPatient(item){
            let cardItem = {}
            let index = item.patientCard.findIndex((item) => {
                return item.sfmr === '1'
            })
            if (index >= 0) {
                cardItem = item.patientCard[index]
            }else{
                cardItem = item.patientCard[0]
            }
            if (item.sfmr ==='1' &&  cardItem.sfmr === '1') {
                this.$router.back()
            }
            this.$dialog.confirm({
                title: '切换就诊人/就诊卡',
                message: `确认切换</br>就诊人:[ ${item.xm} ],</br>就诊卡: [ ${cardItem.jzkh} ]吗?`,
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                confirmButtonColor: '#1E8DFF'
            }).then(() => {
                
                this.getSetDefaultPatientData(item,cardItem)
            }).catch(() => {
                this.$dialog.close()
            })
        },
        //设置默认就诊人
        getSetDefaultPatientData(item,cardItem ) {
            Toast.loading({
                mask: true,
                duration: 0,
                message: "加载中..."
            })
            gjcModel.getSetDefaultPatientData(item.id).then(res=>{
                this.$store.commit("user/setPatient", JSON.stringify(res.data))
                gjcModel.getSetDefaultPatientCardData(item.id, cardItem.id).then(() => {
                    console.log("切换就诊卡", res)
                    this.getHomePatientListData()
                }).catch(() => {
                    setTimeout(() => {
                      Toast.clear() 
                    }, 1000) 
                })
            }).catch(() => {
                setTimeout(() => {
                   Toast.clear() 
                }, 1000) 
            })
        },
        //首页就诊人列表
        getHomePatientListData() {
        const params = {
            yydm: "",
            page: "",
            pageSize: ""
        }
        gjcModel.getHomePatientListData(params).then((res) => {
            this.patientDict = res.data || {}
            this.changeCardActions = []
            const cardList = res.data?.patientCardList || []
            cardList.forEach((item) => {
            let cartDic = {}
            cartDic.jzklxmc = item.jzklxmc 
            cartDic.jzkh = item.jzkh
            cartDic.id = item.patientCardId
            cartDic.patientId = res.data.patientId
            this.changeCardActions.push(cartDic)
            })
            Toast.clear()
            this.$store.commit("user/setPatient", JSON.stringify(res.data))
            this.$router.back()
        }).catch(() => {
            setTimeout(() => {
              Toast.clear()
            }, 1000)
        })
        },
        //添加就诊人页面
        getPatientAddAction(){
            this.$router.push({
                path: '/patientAdd'
            })
        },
        getpatientCardDetail(item){
            this.$router.push({
                path: '/cardList',
                query:{
                    patientId: item.id
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>
.patientlist_page{
    background-color: #fff;
}
.page_main{
    background-color: white;
    padding-top: 100px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 24px);
    .main_item{
        margin: 24px 32px;
        background: rgba(30,141,255,0.1);
        border-radius: 16px;
        padding: 32px 24px;
        .item_delete{
            position: relative;
            top: 25px;
            right: 0px;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .delete_edit{
                margin-right: 16px;
            }
        }
        .item_info{
            display: flex;
            justify-content: space-between;
            .info_img{
                width: 100px;
                height: 100px;
            }
            .info_name{
                margin-left: 24px;
                flex: 1;
                .name_xm{
                    display: flex;
                    align-items: center;
                    font-size: 28px;
                    color: #222B31;
                    line-height: 42px;
                    font-weight: bold;
                    .xm_sfmr{
                        margin-right: 16px;
                        padding: 2px 8px;
                        font-size: 26px;
                        color: #3AC39E;
                        line-height: 30px;
                        background: #E0F5EF;
                        border-radius: 4px;
                        border: 1px solid #3AC39E;
                    }
                    .xm_sjh{
                        margin-left: 16px;
                    }
                }
                .name_zjh{
                    white-space: nowrap;
                    margin-top: 12px;
                    font-size: 26px;
                    color: #6A6D78;
                    letter-spacing: 1px;
                }
            }
        }
        .item_card{
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            padding: 20px 24px;
            background-color:#fff;
            border-radius: 12px;
            .xm_sfmr{
                margin-left: 12px;
                border-radius: 4px;
                padding: 2px 8px;
                font-size: 22px;
                background: rgba(30,141,255,0.1);
                color: #1E8DFF;
                font-weight: normal;
            }
            .card-left{
                display: flex;
                align-items: center;
                .card_xm{
                    font-size: 30px;
                    color: #3C4455;
                    line-height: 42px;
                }
            }
            .card-right{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                color: #9A9A9A;
                line-height: 42px;
            }
            
        }
    }
}
.img {
        display: block;
        margin: 0 auto;
        width: 35px;
        height: 35px;
      }
.page_bottom{
    position: fixed;
    top: 88px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 32px;
    background-color: white;
    z-index: 1000;
    .bottom_add{
        width: 686px;
        padding: 20px 0;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: #1E8DFF;
        line-height: 45px;
        letter-spacing: 1px;
        font-weight: 600;
        border:2px solid #1e8dff;
        .add-image{
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }
    }
}

.ml-12{
    margin-left: 12px;
}

</style>
