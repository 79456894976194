/*
 * @Author: your name
 * @Date: 2021-03-02 12:09:00
 * @LastEditTime: 2024-12-05 16:27:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\utils\constant.js
 */
export const HttpStatusCode = {
  SUCCESS: '200',
  SUCCESS1: '0',
  TOKEN_EXPIRED: '5002',
  UNAUTHORIZED: '5003',
  UNAUTHLOGINOUT:'5004',
  REFRESHTOKEN_EXPIRED:'5005',
  WXCODE_EXPRIRED:'140021'
}
export const PagePath={
  ONLINE:'/',
  SING:'/',
  HEALTH_RECORD:'/',
  HEALTH_KNOWLEDGE:'/'
}
export const DOCTOR_DETAIL='DOCTOR_DETAIL'
export const REG = {
  PHONE_NUM_REG: /^1[3456789]\d{9}$/,
  ID_CARD_REG: /(^\d{15}$)|(^\d{17}(\d|X|x)$)/,
  // 密码正则
  // 必须有数字、小写字母、大写字母，长度6-15位；
  PASSWORD_RED: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15})/
}
// 全局使用的一些常量
export const CONSTANT = {
  // 手机号正则
  PHONE_NUM_REG: /^1[3456789]\d{9}$/,
  // 服务端成功的响应标识
  SUCCESS_CODE: '200' | '0',
  // 未登录标识
  UN_LOGIN_CODE: '401',
  // cookie中存用户信息的key值
  COOKIE_USER_KEY: 'user',
  // localstorage中存环信聊天记录的key值
  STORAGE_MESSAGE_KEY: 'MESSAGE_DICT',
  STORAGE_WAITING_SEND_MESSAGE_KEY: 'WAITING_SEND_MESSAGE',
  // 身份证正则
  ID_CARD_REG: /(^\d{15}$)|(^\d{17}(\d|X|x)$)/
}

// 环信消息类型
export const MessageType = {
  TEXT: 'txt',
  IMAGE: 'img'
}
// 环信消息类型
export const MessageStatus = {
  SENDING: 'sending',
  SENT: 'sent',
  READ: 'read',
  UNREAD: 'unread',
  FAIL: 'fail'
}
// 多人会议类型
export const ConferenceType = {
  NORMAL: 10
}
export const WX_CONST ={
  appid: "wxdea17b6062ca5015",
  secret: "4e1356952c06aeeb4c866d677acbff7a"
}
