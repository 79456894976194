<!--
 * @Page: 费用明细
 * @Author: 张阳帅
 * @Date: 2024-10-11 11:08:52
 * @LastEditTime: 2024-12-18 10:32:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\costList.vue
-->
<template>
  <div class="cost_list_page">
    <van-sticky :offset-top="50">
      <div class="page_top">
        <patient-header :patient-dict="patientDict" />
        <van-tabs type="card" color="#666666" @click="seleteTabsAction">
          <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name" />
        </van-tabs>
        <div class="top_time">
          <div class="start_time">
            {{ startTime }}
          </div>
          <div class="time_line">
            -
          </div>
          <div class="end_time">
            {{ endTime }}
          </div>
        </div>
        <div class="top_price">
          <div class="price_all">
            费用总额<br>
            <a class="all_text">¥{{ countDic.totalAmount }}</a>
          </div>
          <div class="price_self">
            自付总额<br>
            <a class="self_text">¥{{ countDic.selfAmountCount }}</a>
          </div>
          <div class="price_self">
            报销总额<br>
            <a class="self_text">¥{{ countDic.medicareAmount }}</a>
          </div>
        </div>
      </div>
    </van-sticky>
    <page-refresh-load :data-source="dataSource" :total="total" :refreshing="refreshing" :loading="loading" @refreshing="onRefresh" @load-more="onLoad">
      <div v-for="(item,index) in dataSource" :key="index" class="body_item">
        <div class="item_cell" @click="getDetail(item)">
          <div class="cell_left">
            <div class="cell_one">
              <div class="one_name bold">
                {{ item.feeType=='1'?'挂号':'门诊' }}收费: 
              </div>
              <div>
                {{ item.totalAmount }}元
              </div>
            </div>
            <div class="cell_one">
              <div class="one_pay">
                付款日期: {{ item.payTime }}
              </div>
            </div>
          </div>
          <div class="cell_right">
            <div class="three_see">
              查看
            </div>
          </div>
        </div>
      </div>
    </page-refresh-load>
    <van-calendar v-model="dateRangeShow" type="range" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" />
    <van-popup v-model="itemShow" closeable lock-scroll position="bottom" :style="{ height: '60%' }">  
      <div class="popup_page">
        <div class="popup_title">
          项目明细
        </div>
        <div v-if="seleteItem.feeType!='1'" class="popup_body">
          <div v-for="(item, index) in seleteItem.feeDetailList" :key="index" class="popup_body_item">
            <div class="item_list">
              <div class="list_namTitie">
                {{ (item.feeType =='1' || item.feeType == '3') ? item.feeTypeName:(!!item.setName?item.setName:'') }}
              </div>  
              <div class="list_price" style="font-weight: bold;">
                ¥{{ item.hjje }}  
              </div>
            </div>
            <div v-for="(feeItem, feeIndex) in item.feeItemList" :key="feeIndex" class="item_list">
              <div class="list_name">
                {{ feeItem.feeItemName }}
              </div>
              <div class="list_price">
                ¥{{ feeItem.feeItemUnitPrice }}  x{{ feeItem.feeItemAmount }}
              </div>
            </div>
            <div v-if="item.feeType=='3'" class="item_list">
              <div class="list_num">
                共计:{{ item.feeItemList[0].preStickNum }}贴
              </div>
            </div>
            <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 5px' }" />
          </div>
        </div>
        <div v-else class="popup_body">
          <div class="popup_body_item">
            <div class="item_list">
              <div class="list_price">
                {{ seleteItem.deptName }}-{{ seleteItem.docName }}
              </div>
              <div class="list_price">
                ¥{{ seleteItem.totalAmount }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
import pageRefreshLoad from '@/assets/js/mixin/pageRefreshLoad'
import { Toast } from 'vant'

export default {
    name:'CostList',
    mixins: [pageRefreshLoad],
    data (){
        return{
            patientDict:{},//就诊人字典
            countDic:{},
            tabs:[{
                name:'近一个月',
                type:'1',
                id:''
            },{
                name:'近三个月',
                type:'2',
                id:''
            },{
                name:'自定义',
                type:'3',
                id:''
            }],
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(),
            dateRangeShow:false,//日期范围选择器
            dataSource:[],
            refreshing:false,
            loading:false,
            startTime:this.getTimeDay(30),//开始时间
            endTime:this.getTimeDay(0),//结束时间
            page:1,
            total:0,
            itemShow:false,
            seleteItem:{}
        }
    },
    created(){
      this.patientDict = JSON.parse(this.$store.getters.patient)
    },
    mounted() {
      this.onRefresh()
      this.getOrderInfoCountData()
    },
    methods:{
      onRefresh() {
        this.page = 1
        this.dataSource = []
        this.total = 0
        this.getList()
      },
      //费用明细
      async getList(){
        const params = {
          page: this.page,
          pageSize: 10,
          startTime:this.startTime,
          //结束时间改为当日最后时分
          endTime:this.endTime + ' 23:59:59',
          hospCode: '', //医院代码
          patientId:this.patientDict.patientId,
          patientCardId: this.patientDict.patientCardList[0].patientCardId
        }
        Toast.loading({
          mask: true,
          duration: 0,
          message: "加载中..."
        })
        const res = await gjcModel.getOrderInfoListData(params)
        //console.log('费用明细', res)
        this.dataSource.push(...(res.data || []))
        this.total = res.count || 0
        Toast.clear()
      },
      getOrderInfoCountData(){
        const params = {
          startTime:this.startTime,
          //结束时间改为当日最后时分
          endTime:this.endTime + ' 23:59:59',
          hospCode: '', //医院代码
          patientId:this.patientDict.patientId,
          patientCardId: this.patientDict.patientCardList[0].patientCardId
        }
        gjcModel.getOrderInfoCountData(params).then((res) => {
          this.countDic = res.data
        })
      },
      getDetail(item){
        this.seleteItem = item
        this.itemShow = true
        console.log(item)
        // this.$router.push({
        //   path:'/costDetail',
        //   query:{
        //     id:item.id
        //   }
        // })
      },
      dateSubstring(dateStr){
        let time = dateStr
        if(dateStr){
          time = time.substring(0,10)
        }
        return time
      },
      //切换类型
      seleteTabsAction(name){
        this.dateRangeShow = false
        this.dataList = []
        if(name==0){
          this.startTime = this.getTimeDay(30)
          this.onRefresh()
          this.getOrderInfoCountData()
        }else if(name==1){
          this.startTime = this.getTimeDay(90)
          this.onRefresh()
          this.getOrderInfoCountData()
        }else if(name==2){
          this.dateRangeShow = true
        }
        console.log(name, this.dateRangeShow)
      },
      onConfirm(date) {
        const [start, end] = date
        this.startTime = this.formatDate(start)
        this.endTime = this.formatDate(end)
        this.dateRangeShow = false
        this.onRefresh()
        this.getOrderInfoCountData()
      },
      formatDate(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
      getTimeDay(day) {
        var today = new Date()
        var targetday_milliseconds = today.getTime() - 1000 * 60 * 60 * 24 * day
        today.setTime(targetday_milliseconds) //注意，这行是关键代码
        var tYear = today.getFullYear()
        var tMonth = today.getMonth()
        var tDate = today.getDate()
        tMonth = this.doHandleMonth(tMonth + 1)
        tDate = this.doHandleMonth(tDate)
        return tYear + "-" + tMonth + "-" + tDate
      },
      doHandleMonth(month) {
        var m = month
        if (month.toString().length == 1) {
          m = "0" + month
        }
        return m
      }
    }
}
</script>
<style scoped lang='less'>
    .cost_list_page{
        background-color: #f5f5f5;
        height: 100vh;
    }
    .page_top{
        border-bottom: 1px solid #f5f5f5;
        background-color: white;
        .top_time{
          display: flex;
          justify-content: space-between;
          justify-items: center;
        .start_time{
          margin: 10px 10px 10px 30px;
          padding: 10px;
          text-align: center;
          width: 40%;
          border: 1px solid #ccc;
          font-size: 30px;
          color: #333;
        }
        .time_line{
          margin: 20px 0px;
          font-size: 30px;
          color: #333;
        }
        .end_time{
          margin: 10px 30px 10px 10px;
          padding: 10px;
          text-align: center;
          width: 40%;
          border: 1px solid #ccc;
          font-size: 30px;
          color: #333;
        }
      }
      .top_price{
        display: flex;
        justify-content: space-between;
        justify-self: center;
        margin: 10px 0;
        font-size: 30px;
        color: #666;
        width: 100%;
        .price_all{
          width: 33%;
          text-align: center;
          .all_text{
            color: #1e80ff;
          }
        }
        .price_self{
          width: 33%;
          text-align: center;
          .self_text{
            color: #333;
          }
        }
      }
    }
    .body_item{
        display: flex;
        justify-content: space-between;
        background-color: white;
        padding: 24px;
        margin-bottom: 24px;
        border-radius: 12px;
        .item_cell{
            width: 100%;
            display: flex;
            justify-content: space-between;
            justify-items: center;
            font-size: 30px;
            line-height: 50px;
            color: #333;
            .cell_left{
              flex: 1;
              margin-right: 24px;
              .cell_one{
                display: flex;
                justify-items: center;
                .one_name{
                  margin-right: 12px;
                }
                .bold{
                  font-weight: bold;
                }
                .one_pay{
                    font-size: 26px;
                    line-height: 50px;
                    color: #999;
                }
              }
            }
            .cell_right{
              display: flex;
              align-items: center;
                .three_see{
                    padding: 8px 20px;
                    height: 40px;
                    line-height: 40px;
                    border: 2px solid #666;
                    border-radius: 40px;
                    color:#666;
                }
            }
        }
    }
    .popup_page{
      position: relative;
      padding: 0 30px;
    }
    .popup_title{
      margin: 10px;
      font-size: 36px;
      width: 100%;
      text-align: center;
      color: #333;
      line-height: 60px;
      font-weight: bold;
    }
    .popup_body{
      padding: 20px 20px;
      height: 50vh;
      overflow: scroll;
      background-color: #f5f5f5;
      border-radius: 12px;
      .popup_body_item{
        display: flex;
        flex-direction: column;
        font-size: 30px;
        color: #333;
        .item_title{
          font-size: 30px;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
        }
        .item_list{
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          font-size: 28px;
          color: #666;
          .list_name{
            width: 65%;
            font-size: 24px;
          }
          .list_namTitie{
            width: 65%;
            font-size: 28px;
            font-weight: bolder;
          }
          .list_price{
            font-size: 28px;
            color: #666;
          }
          .list_num{
            width: 100%;
            text-align: right;
            color: #333;
          }
        }
      }
    }
</style>
