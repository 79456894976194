<!--
 * @Date: 2024-12-13 15:38:57
 * @LastEditTime: 2024-12-16 13:14:04
 * @Description: 
 * @FilePath: \szlsnk-user-mp\src\components\patient\patientHeader.vue
-->
<template>
  <div class="patient-cell" @click="gotoPatientList">
    <div v-if="patientDict && patientDict.xm" class="cell-info">
      {{ patientDict.xm }} <span class="info-card">{{ patientDict.patientCardList[0].jzklxmc }} {{ patientDict.patientCardList[0].jzkh }}</span>
    </div>
    <div v-else />
    <div class="cell-right">
      <van-icon name="arrow" size="20" />
    </div>
    <!-- 定义一个默认插槽 -->
    <slot />
  </div>
</template>
<script>
export default {
  name:'PatientHeader',
    props: {
        patientDict: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        gotoPatientList() {
            this.$router.push('/patientList')
        }
    }
}
</script>

<style lang="less" scoped>
.patient-cell {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 24px;
    color: white;
    background-color: #1e80ff;
    border-bottom: 1px solid #f5f5f5;
    font-size: 30px;
    .info-card{
        margin-left: 12px;
    }
}
</style>