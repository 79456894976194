<!--
 * @Author: your name
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2024-12-13 16:10:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\reportList.vue
-->
<template>
  <div class="reportList_page">
    <div class="page_his">
      <iframe :src="externalUrl" width="100%" height="100%" frameborder="0" allowfullscreen />
    </div>
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
// import loadMore from '@/assets/js/mixin/load-more'

export default {
    name:'PeportList',
    // mixins: [loadMore],
    data (){
        return{
            patientDict:{},//就诊人字典
            patientCardId:'',
            patientInfoId:'',
            page:1,
            pageSize:10,
            total:0,
            loading:false,
            dataList:[],
            externalUrl:'',
            sjlb: '', //业务类别 1：门诊 2：住院
            bglx:  '' //报告类型 JY：检验 JC：检查 TJ：体检
        }
    },
    created(){
        this.patientDict = JSON.parse(this.$store.getters.patient)
    },
    mounted() {
        if(this.$route.query.type=='1'){
            this.sjlb = '1'
        }else if(this.$route.query.type=='2'){
            this.sjlb = '2'
        }else if(this.$route.query.type=='3'){
            this.bglx = 'TJ'
        }
        this.getReportModeData()
    },
    methods:{
        //获取报告模式
        getReportModeData(){
            console.log('获取报告模式')
            gjcModel.getReportModeData().then(res=>{
                console.log('获取报告模式', res)
                this.getDataCenterReportData()
            })
        },
        //获取数据中心报告
        getDataCenterReportData(){
            const params = {
                sjlb: this.sjlb, //业务类别 1：门诊 2：住院
                bglx: this.bglx, //报告类型 JY：检验 JC：检查 TJ：体检
                patientInfoId: this.patientDict.patientId,
                patientCardId: this.patientDict.patientCardList[0].patientCardId
            }
            gjcModel.getDataCenterReportData(params).then(res=>{
                this.externalUrl=res.data.url
                console.log('获取HisView报告', this.externalUrl)
            })
        }
    }
}
</script>
<style scoped lang='less'>
.reportList_page{
    .page_his{
        background-color: #fff;
        width: 100%;
        height: 100%;
    }
}

</style>
