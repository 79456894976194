<!--
 * @Author: your name
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2024-12-13 16:14:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\user\consultation.vue
-->
<template>
  <div>
    <!-- <patientSelect @onConfirm="patientConfirm" /> -->
    <van-sticky :offset-top="50">
      <div class="page_top">
        <patient-header :patient-dict="patientDict" />
      </div>
    </van-sticky>
    <load-more :data-source="dataList" :total="total" :loading="loading" @load-more="onLoad">
      <consultation-item :data="dataList" :username="username" />
    </load-more>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
import loadMore from '@/assets/js/mixin/load-more'
export default {
  name:'Consultation',//问诊
  mixins: [loadMore],
  data (){
    return{
      patientDict: {},//就诊人字典
      Picker:false,
      patientIdCard:'',
      dataList:[],
      username:'',
      patientId:''
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.dir('enter')

  //   next(vm => {
  //     if (vm.patientIdCard) {
  //       vm.getList()
  //     }
  //   })
  // },
  created(){
    this.patientDict = JSON.parse(this.$store.getters.patient)
    this.patientId = this.patientDict.patientId
    this.patientCardId = this.patientDict.patientCardList[0].patientCardId
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      let params={
        patientCardId: this.patientCardId,
        patientInfoId: this.patientId,
        "flag":"3",
        page: this.page,
        pageSize: this.pageSize
      }
      userModel.appointmentList(params).then(res=>{
          this.dataList.push(...(res.data||[]))
          this.total = res.count
          this.loading = false
      })
    },
    patientConfirm(value){
      // console.log('confirm:', value)
      this.patientIdCard=value.zjh||""
      this.username=value.userName
      // console.log('username:', this.username)
      this.page=1
      this.dataList=[]
      this.getList()
    },
     onLoad(){
      this.loading=true
      this.page+=1
      this.getList()
    }

  }

}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.page-box{
  padding: 0 30px;
}
.page_top {
  background-color: #1e80ff;
  border-bottom: 1px solid #f5f5f5;
}
</style>
