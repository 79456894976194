<!--
 * @Page: 电子处方
 * @Author: 张阳帅
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2024-12-18 10:38:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\prescription.vue
-->
<template>
  <div class="prescription_page">
    <van-sticky :offset-top="50">
      <div class="page_top">
        <patient-header :patient-dict="patientDict" />
        <van-tabs type="card" color="#666666" @click="seleteTabsAction">
          <van-tab v-for="(item, index) in tabs" :key="index" :title="item.name" />
        </van-tabs>
        <div class="top_time">
          <div class="start_time">
            {{ startTime }}
          </div>
          <div class="time_line">
            -
          </div>
          <div class="end_time">
            {{ endTime }}
          </div>
        </div>
      </div>
    </van-sticky>
    <page-refresh-load :data-source="dataSource" :total="total" :refreshing="refreshing" :loading="loading" @refreshing="onRefresh" @load-more="onLoad">
      <div v-for="(item, index) in dataSource" :key="index" class="body_item">
        <div class="item_cell" @click="getDetail(item)">
          <div class="cell_left">
            <div class="cell_one">
              <div class="one_name bold">
                {{ item.preTypeName }}
              </div>
              <div class="one_pay">
                {{ item.preCreateTime }}
              </div>
            </div>
            <div class="cell_one">
              <div class="one_name">
                就诊人: {{ item.patName }}
              </div>
            </div>
            <div class="cell_one">
              <div class="one_name">
                诊断: {{ item.mainDiagnosisName }}
              </div>
            </div>
          </div>
          <div class="cell_right">
            <div class="three_see">
              查看
            </div>
          </div>
        </div>
      </div>
    </page-refresh-load>
    <van-calendar v-model="dateRangeShow" type="range" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" />
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
import pageRefreshLoad from '@/assets/js/mixin/pageRefreshLoad'
import { Toast } from 'vant'

export default {
  name: 'Prescription',
  mixins: [pageRefreshLoad],
  data() {
    return {
      patientDict: {},//就诊人字典
      tabs: [{
        name: '近一个月',
        type: '1',
        id: ''
      }, {
        name: '近三个月',
        type: '2',
        id: ''
      }, {
        name: '自定义',
        type: '3',
        id: ''
      }],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
      dataSource: [],
      dateRangeShow: false,//日期范围选择器
      page: 1,
      pageSize: 10,
      total: 0,
      refreshing: false,
      loading: false,
      startTime: this.getTimeDay(30),//开始时间
      endTime: this.getTimeDay(0)//结束时间
    }
  },
    created(){
     this.patientDict = JSON.parse(this.$store.getters.patient)
  },
  mounted() {
    this.onRefresh()
  },
  methods: {
    onRefresh() {
        this.page = 1
        this.dataSource = []
        this.total = 0
        this.getList()
      },
    //获取电子处方列表
    async getList() {
      Toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      })
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        startTime: this.startTime,
        endTime: this.endTime,
        hospCode: '', //医院代码
        patCardId: this.patientDict.patientCardList[0].patientCardId
      }
      const res = await gjcModel.getPrescriptionListData(params)
      Toast.clear()
      this.dataSource.push(...(res.data || []))
      this.total = res.count || 0
    },
    getDetail(item) {
      this.$router.push({
        path: '/gjcPrescriptionDetail',
        query: {
          id: item.preId
        }
      })
    },
    dateSubstring(dateStr) {
      let time = dateStr
      if (dateStr) {
        time = time.substring(0, 10)
      }
      return time
    },
    //切换类型
    seleteTabsAction(name) {
      this.dateRangeShow = false
      this.page = 1
      this.dataSource = []
      if (name == 0) {
        this.startTime = this.getTimeDay(30)
        this.getList()
      } else if (name == 1) {
        this.startTime = this.getTimeDay(90)
        this.getList()
      } else if (name == 2) {
        this.dateRangeShow = true
      }
      // console.log(name, this.dateRangeShow)
    },
    onConfirm(date) {
      const [start, end] = date
      this.startTime = this.formatDate(start)
      this.endTime = this.formatDate(end)
      this.dateRangeShow = false
      this.page = 1
      this.getList()
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getTimeDay(day) {
      var today = new Date()
      var targetday_milliseconds = today.getTime() - 1000 * 60 * 60 * 24 * day
      today.setTime(targetday_milliseconds) //注意，这行是关键代码
      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + "-" + tMonth + "-" + tDate
    },
    doHandleMonth(month) {
      var m = month
      if (month.toString().length == 1) {
        m = "0" + month
      }
      return m
    }
  }
}
</script>
<style scoped lang='less'>
.prescription_page {
  background-color: #f5f5f5;
  height: 100vh;
}

.page_top {
  border-bottom: 1px solid #f5f5f5;
  background-color: white;

  .top_time {
    display: flex;
    justify-content: space-between;
    justify-items: center;

    .start_time {
      margin: 10px 10px 10px 30px;
      padding: 10px;
      text-align: center;
      width: 40%;
      border: 1px solid #ccc;
      font-size: 30px;
      color: #333;
    }

    .time_line {
      margin: 20px 0px;
      font-size: 30px;
      color: #333;
    }

    .end_time {
      margin: 10px 30px 10px 10px;
      padding: 10px;
      text-align: center;
      width: 40%;
      border: 1px solid #ccc;
      font-size: 30px;
      color: #333;
    }
  }
}

.body_item{
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 12px;
    .item_cell{
        width: 100%;
        display: flex;
        justify-content: space-between;
        justify-items: center;
        font-size: 30px;
        line-height: 50px;
        color: #333;
        .cell_left{
          flex: 1;
          margin-right: 24px;
          .cell_one{
            display: flex;
            justify-items: center;
            .bold{
              font-weight: bold;
            }
            .one_name{
              margin-right: 12px;
            }
            .one_pay{
                font-size: 26px;
                line-height: 50px;
                color: #999;
            }
          }
        }
        .cell_right{
          display: flex;
          align-items: center;
            .three_see{
                padding: 8px 20px;
                height: 40px;
                line-height: 40px;
                border: 2px solid #666;
                border-radius: 40px;
                color:#666;
            }
        }
    }
}
</style>
