import { render, staticRenderFns } from "./registerPayList.vue?vue&type=template&id=479f3074&scoped=true"
import script from "./registerPayList.vue?vue&type=script&lang=js"
export * from "./registerPayList.vue?vue&type=script&lang=js"
import style0 from "./registerPayList.vue?vue&type=style&index=0&id=479f3074&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479f3074",
  null
  
)

export default component.exports